<template>
  <div class="">    

    
    <WeekViewActionsEdit :weekid="week.id" :owner="diary.item_user.id" :diarylink="diary.link" @remove="removeWeek"/>      

    
    <WeekViewConditions 
      :days="week.days"
      :faza="week.faza"
      :props="week.props"
      :data="week.item_flowering" />
    
    <WeekViewNutrients :nutrients="week.item_flowering.items_nutrient" />

    <WeekViewMethods :items-method="week.item_flowering.items_method" />

    <WeekViewComment :week="week" :user="diary.item_user"/>

  </div>
</template>

<script>
 
export default { 
  props: ['week', 'diary'],
  components: {     
    
  },  
  data () {
    return {      
      link: this.$route.params['week'],
      active_tab: 'info', 
      query: '', 
      data: {}
    }
  },
  created() {    
    // console.log(this.week); 
    // console.log('week'); 
    // console.log(this.$constants.type_faza_short); 
  },
  methods: {    
    removeWeek(){
      this.$emit('removeweek');
    }
  }
}
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
