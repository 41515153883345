<template>
  <div>    
    
    <WeekViewActionsEdit :weekid="week.id" :owner="diary.item_user.id" :diarylink="diary.link" @remove="removeWeek"/>      

    <WeekViewGermSeedsStat :data="week" />

      
    <WeekViewNutrients :nutrients="week.item_germination.items_nutrient" />
    
    <WeekViewComment :week="week" :user="diary.item_user"/>

  </div>
</template>

<script>
 
export default { 
  props: {
    week: {
      type: Object
    },
    diary: {
      type: Object
    },
  },
  components: {     
   
  },  
  data () {
    return {      
      link: this.$route.params['week'],
      active_tab: 'info', 
      query: '', 
      data: {}
    }
  },
  created() {    
    // console.log('this.$const'); 
    // console.log(this.$constants.type_faza_short); 
  },
  methods: {   
    removeWeek(){      
      this.$emit('removeweek');
    }
  }
}
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
