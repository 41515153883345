<template>
  <div class="">    

    
    <WeekViewActionsEdit 
      :is-harvest="true"
      :weekid="week.id" 
      :owner="diary.item_user.id" 
      :diarylink="diary.link" 
      @remove="emits('removeweek')"
      />      

    <WeekViewHarvestSeedReview 
      :data="week" 
      /> 


    <WeekViewHarvestConditions 
      :data="week" 
      /> 

    <WeekViewHarvestConditionsCalc 
      :weight="week.props.harvest_weight" 
      :wetweight="week.props.harvest_wet_weight" 
      :plant="week.props.harvest_plant" 
      :watt="week.props.harvest_watt" 
      :space="week.props.harvest_space" 
      /> 
 
  
    <WeekViewTastes 
      :taste="week.item_harvest.item_review_seed.items_taste"   
      />
    
    <WeekViewEffects 
      :positive="week.item_harvest.item_review_seed.items_effect_positive" 
      :negative="week.item_harvest.item_review_seed.items_effect_negative" 
      :medical="week.item_harvest.item_review_seed.items_effect_medical" 
      :indica="week.item_harvest.item_review_seed.props.indica" 
      />


    <WeekViewHarvestNutrientReview 
      :data="week.item_harvest.items_nutrient"
      />

 
    <WeekViewComment 
      :week="week" 
      :user="diary.item_user"
      />



  </div>
</template>

<script setup>
 
 const emits = defineEmits(['removeweek'])

const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  diary: {
    type: Object,
    required: true
  }
})




</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
